<template>
  <div class="companyAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="'消防主机型号详情'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="info"
      v-watermark
    >
      <van-collapse
        v-model="activeNames"
        accordion
      >
        <van-collapse-item
          title="消防主机型号信息"
          name="1"
        >
          <van-cell
            center
            title="主机型号:"
            class="more"
            :value="hostModelInfo.model || '--'"
          />
          <van-cell
            center
            title="主机品牌:"
            class="more"
            :value="hostModelInfo.brand || '--'"
          />
          <van-cell
            center
            title="主机类型:"
            class="more"
            :value="hostModelInfo.modelType || '--'"
          />
          <van-cell
            center
            title="状态:"
          >
            <template #default>
              <span
                style="color: #00a854"
                v-if="hostModelInfo.status === 0"
              >在用</span>
              <span
                style="color: #d9001b"
                v-else-if="hostModelInfo.status === 1"
              >停用</span>
              <span v-else>--</span>
            </template>
          </van-cell>
          <van-cell
            center
            title="更新时间:"
            :value="hostModelInfo.updateDatetime | dateFormat"
          />
          <userJump
            title="更新人:"
            :userList="hostModelInfo.updateBy"
          ></userJump>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { getHostModelInfo } from '@/api/hostModel'
import { mapState } from 'vuex'
import wx from 'weixin-js-sdk'
import index from '@/mixins'

export default {
  mixins: [index],
  name: 'companyAddressInfo',
  data() {
    return {

      activeNames: '1',
      hostModelInfo: {},
      loadingShow: false
    }
  },
  async created() {
    this.loadingShow = true
    try {
      const { data } = await getHostModelInfo(this.$route.params.id)
      if (data.data) {
        this.hostModelInfo = data.data
        wx.onMenuShareAppMessage({
          title: this.hostModelInfo.model, // 分享标题
          desc: `主机品牌:${this.hostModelInfo.brand ? this.hostModelInfo.brand : '--'
            }\n主机类型:${this.hostModelInfo.modelType ? this.hostModelInfo.modelType : '--'
            }`, // 分享描述
          link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
          enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          error: function (res) {
            alert('暂无分享权限')
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }
        })
        this.loadingShow = false
      } else {
        this.loadingShow = false
        this.$toast.fail({
          message: '该主机型号已被删除!!',
          duration: 500
        })
      }
    } catch (error) {
      this.loadingShow = false
      if (
        error?.response?.status === 401
      ) {
        return
      }
      if (error.message.includes('timeout')) {
        this.$toast.fail({
          message: '网络不好，请稍后再试!',
          duration: 500
        })
        return
      }
      this.$toast.fail({
        message: '当前网络异常，请稍后再试!!',
        duration: 500
      })
    }
  },
  computed: {
    ...mapState('user', ['jurisdiction'])
  }

}
</script>

<style lang="scss" scoped>
.companyAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .info {
      height: calc(100% - 46px);
      overflow: auto;

      .van-collapse {
        background-color: transparent;

        .van-collapse-item {
          background-color: transparent;

          .van-collapse-item__title {
            background-color: white;
            font-weight: 700;
          }

          .van-collapse-item__wrapper {
            background-color: transparent;

            .van-collapse-item__content {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
</style>
